import Header from './Header'
import Footer from './Footer'
import ConnectButton from './ConnectButton.js'
import { Accordion, AccordionContext, Card, Tab, Tabs, useAccordionToggle } from 'react-bootstrap'
import { useState, useContext, useEffect } from 'react'
import eventBus from './EventBus'

let bucketAllocationTime = 0
let bucketClaimedTime = 0
let lepaTime = 0

export default function Dashboard() {

    const resolution = window.innerWidth
    const isMobile = resolution >= 320 && resolution <= 480

    const [isWalletConnected, updateConnection] = useState(false)
    const [unlockedValue, setUnlocked] = useState(0)
    const [buttonClassName, setButtonClass] = useState("btn btn-primary claim-btn")
    const [claimedString, setClaimedString] = useState("Claim Now")
    const [allocationValue, setAllocation] = useState(0)
    const [claimedValue, setClaimed] = useState(0)
    const [lepaValue, setLepa] = useState(0)


    const [currentBucket, setCurrenctBucket] = useState("stretegic")

    const [ethAddr, setETHAddress] = useState('0')

    const [loaderShow, setLoader] = useState(false)
    const [networkLoaderShow, setNetworkLoader] = useState(true)


    useEffect(() => {
        initEvent()
    })

    useEffect(() => () => removeEvents(), [])

    function removeEvents() {
        eventBus.remove("lepaBalanceEvent")
        eventBus.remove("bucketClaimResponse")
        eventBus.remove("bucketResponse")
        eventBus.remove("networkChain")
        eventBus.remove("claimedStatus")

    }

    function initEvent() {
        eventBus.on('claimedStatus', (params) => {
            bucketClaimedTime = bucketClaimedTime + 1
            if (bucketClaimedTime < 3) {
                setClaimedString(params.msg)
                if (params.status) {
                    setTimeout(() => {
                        handleSelect(currentBucket)
                        setButtonClass("btn btn-primary claim-btn")
                    }, 2000)
                } else {
                    setButtonClass("btn btn-primary btn-loader claim-btn disabled")
                }
                setTimeout(() => {
                    bucketClaimedTime = 0
                }, 2000)
            }
        })

        eventBus.on('networkChain', (params) => {
            bucketAllocationTime = bucketAllocationTime + 1
            if (bucketAllocationTime < 3) {
                setNetworkLoader(params.status)
                setTimeout(() => {
                    bucketAllocationTime = 0
                }, 1000)
            }
        })

        eventBus.on('bucketResponse', (params) => {
            bucketAllocationTime = bucketAllocationTime + 1
            if (bucketAllocationTime < 3) {
                setAllocation(params.allocation)
                setClaimed(params.claimed)
                setTimeout(() => {
                    bucketAllocationTime = 0
                }, 1000)
            }
        })

        eventBus.on('bucketClaimResponse', (params) => {
            bucketClaimedTime = bucketClaimedTime + 1
            if (bucketClaimedTime < 3) {
                if (params.data === 0) {
                    setButtonClass("btn btn-primary claim-btn disabled")
                } else {
                    setButtonClass("btn btn-primary claim-btn")
                }
                setUnlocked(params.data)
                setTimeout(() => {
                    bucketClaimedTime = 0
                }, 1000)
            }
        })

        eventBus.on('lepaBalanceEvent', (params) => {
            lepaTime = lepaTime + 1
            setLepa(params.balanceOf)
            if (lepaTime < 3) {
                // setLepa(params.balanceOf)                
                setTimeout(() => {
                    setLoader(false)
                    lepaTime = 0
                }, 1000)
            }
        })
    }

    function setChainName(chainName) {
        //Chain Event
    }

    function accountEvent(params) {
        setLoader(true)
        setETHAddress(params)
        eventBus.dispatch('accountBalEvent', { data: params })
        eventBus.dispatch('bucketAllocationEvent', { data: params, type: 'stretegic' })
        // eventBus.dispatch('accountEvent', { data: params })
    }

    function claimNowProcess() {
        if (unlockedValue !== 0 && claimedString === "Claim Now") {
            eventBus.dispatch('claimNowEvent', { data: ethAddr, type: currentBucket })
        }
    }

    function accountBalEvent(params) {
        //Balance Event
    }

    function handleSelect(key) {
        setLoader(true)
        setClaimedString("Claim Now")
        if (key === "stretegic") {
            setCurrenctBucket("stretegic")
            eventBus.dispatch('bucketAllocationEvent', { data: ethAddr, type: 'stretegic' })
        } else if (key === "foundational") {
            setCurrenctBucket("foundational")
            eventBus.dispatch('bucketAllocationEvent', { data: ethAddr, type: 'foundational' })
        } else if (key === "marketing") {
            setCurrenctBucket("marketing")
            eventBus.dispatch('bucketAllocationEvent', { data: ethAddr, type: 'marketing' })
        } else if (key === "liquidity") {
            setCurrenctBucket("liquidity")
            eventBus.dispatch('bucketAllocationEvent', { data: ethAddr, type: 'liquidity' })
        } else if (key === "team") {
            setCurrenctBucket("team")
            eventBus.dispatch('bucketAllocationEvent', { data: ethAddr, type: 'team' })
        } else if (key === "advisors") {
            setCurrenctBucket("advisors")
            eventBus.dispatch('bucketAllocationEvent', { data: ethAddr, type: 'advisors' })
        }
    }




    return (
        <>
            <div className="site-content">
                <Header />
                <div className="header-block"></div>

                <main className="main-content dashboard-page">
                    <div className="tokenomics-section section">
                        <div className="container">
                            <div className="page-top">
                                <h1>Dashboard</h1>
                                <p>It is important for you to know economics of the tokens and how we are going to utilise the funds.</p>
                            </div>

                            {!isWalletConnected ? (<ConnectButton
                                chainName={setChainName.bind(this)}
                                sendAccount={accountEvent.bind(this)}
                                updateConnection={updateConnection.bind(this)}
                                setaccountAddBal={accountBalEvent.bind(this)}
                                className="btn btn-primary btn-lg"
                            />)
                                :
                                !isMobile ? (<div className="token-info">
                                    <Tabs defaultActiveKey="stretegic" onSelect={handleSelect}>
                                        <Tab eventKey="stretegic" title="Strategic">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="box">
                                                        <div className="value">{allocationValue}</div>
                                                        <h3>Allocation</h3>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="box">
                                                        <div className="value">{unlockedValue}</div>
                                                        <h3>Unlocked</h3>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="box">
                                                        <div className="value">{claimedValue}</div>
                                                        <h3>Claimed</h3>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="box balance">
                                                        <div className="value">{lepaValue}</div>
                                                        <h3>Current Balance</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center"><button onClick={claimNowProcess} className={buttonClassName}>{claimedString}</button></div>
                                        </Tab>
                                        <Tab eventKey="foundational" title="Foundational">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="box">
                                                        <div className="value">{allocationValue}</div>
                                                        <h3>Allocation</h3>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="box">
                                                        <div className="value">{unlockedValue}</div>
                                                        <h3>Unlocked</h3>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="box">
                                                        <div className="value">{claimedValue}</div>
                                                        <h3>Claimed</h3>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="box balance">
                                                        <div className="value">{lepaValue}</div>
                                                        <h3>Current Balance</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center"> <button className={buttonClassName}>{claimedString}</button> </div>
                                        </Tab>
                                        <Tab eventKey="marketing" title="Marketing">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="box">
                                                        <div className="value">{allocationValue}</div>
                                                        <h3>Allocation</h3>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="box">
                                                        <div className="value">{unlockedValue}</div>
                                                        <h3>Unlocked</h3>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="box">
                                                        <div className="value">{claimedValue}</div>
                                                        <h3>Claimed</h3>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="box balance">
                                                        <div className="value">{lepaValue}</div>
                                                        <h3>Current Balance</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center"><button onClick={claimNowProcess} className={buttonClassName}>{claimedString}</button></div>
                                        </Tab>

                                        <Tab eventKey="team" title="Team">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="box">
                                                        <div className="value">{allocationValue}</div>
                                                        <h3>Allocation</h3>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="box">
                                                        <div className="value">{unlockedValue}</div>
                                                        <h3>Unlocked</h3>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="box">
                                                        <div className="value">{claimedValue}</div>
                                                        <h3>Claimed</h3>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="box balance">
                                                        <div className="value">{lepaValue}</div>
                                                        <h3>Current Balance</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center"><button onClick={claimNowProcess} className={buttonClassName}>{claimedString}</button></div>
                                        </Tab>

                                        <Tab eventKey="advisors" title="Advisors">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="box">
                                                        <div className="value">{allocationValue}</div>
                                                        <h3>Allocation</h3>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="box">
                                                        <div className="value">{unlockedValue}</div>
                                                        <h3>Unlocked</h3>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="box">
                                                        <div className="value">{claimedValue}</div>
                                                        <h3>Claimed</h3>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="box balance">
                                                        <div className="value">{lepaValue}</div>
                                                        <h3>Current Balance</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center"><button onClick={claimNowProcess} className={buttonClassName}>{claimedString}</button></div>
                                        </Tab>

                                    </Tabs>
                                </div>
                                ) :

                                    (<div className="token-info">
                                        <Accordion onSelect={handleSelect} defaultActiveKey="stretegic">
                                            <Card>
                                                <Card.Header>
                                                    <AccordionToggle eventKey="stretegic">Strategic</AccordionToggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="stretegic">
                                                    <Card.Body>
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className="box">
                                                                    <div className="value">{allocationValue}</div>
                                                                    <h3>Allocation</h3>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="box">
                                                                    <div className="value">{unlockedValue}</div>
                                                                    <h3>Unlocked</h3>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="box">
                                                                    <div className="value">{claimedValue}</div>
                                                                    <h3>Claimed</h3>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="box balance">
                                                                    <div className="value">{lepaValue}</div>
                                                                    <h3>Current Balance</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="text-center"><button onClick={claimNowProcess} className={buttonClassName}>{claimedString}</button></div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                            <Card>
                                                <Card.Header>
                                                    <AccordionToggle eventKey="foundational">Foundational</AccordionToggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="foundational">
                                                    <Card.Body>
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className="box">
                                                                    <div className="value">{allocationValue}</div>
                                                                    <h3>Allocation</h3>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="box">
                                                                    <div className="value">{unlockedValue}</div>
                                                                    <h3>Unlocked</h3>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="box">
                                                                    <div className="value">{claimedValue}</div>
                                                                    <h3>Claimed</h3>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="box balance">
                                                                    <div className="value">{lepaValue}</div>
                                                                    <h3>Current Balance</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="text-center"><button onClick={claimNowProcess} className={buttonClassName}>{claimedString}</button></div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                            <Card>
                                                <Card.Header>
                                                    <AccordionToggle eventKey="marketing">Marketing</AccordionToggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="marketing">
                                                    <Card.Body>
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className="box">
                                                                    <div className="value">{allocationValue}</div>
                                                                    <h3>Allocation</h3>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="box">
                                                                    <div className="value">{unlockedValue}</div>
                                                                    <h3>Unlocked</h3>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="box">
                                                                    <div className="value">{claimedValue}</div>
                                                                    <h3>Claimed</h3>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="box balance">
                                                                    <div className="value">{lepaValue}</div>
                                                                    <h3>Current Balance</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="text-center"><button onClick={claimNowProcess} className={buttonClassName}>{claimedString}</button></div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                            <Card>
                                                <Card.Header>
                                                    <AccordionToggle eventKey="team">Team</AccordionToggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="team">
                                                    <Card.Body>
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className="box">
                                                                    <div className="value">{allocationValue}</div>
                                                                    <h3>Allocation</h3>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="box">
                                                                    <div className="value">{unlockedValue}</div>
                                                                    <h3>Unlocked</h3>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="box">
                                                                    <div className="value">{claimedValue}</div>
                                                                    <h3>Claimed</h3>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="box balance">
                                                                    <div className="value">{lepaValue}</div>
                                                                    <h3>Current Balance</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="text-center"><button onClick={claimNowProcess} className={buttonClassName}>{claimedString}</button></div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                            <Card>
                                                <Card.Header>
                                                    <AccordionToggle eventKey="advisors">Advisors</AccordionToggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="advisors">
                                                    <Card.Body>
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className="box">
                                                                    <div className="value">{allocationValue}</div>
                                                                    <h3>Allocation</h3>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="box">
                                                                    <div className="value">{unlockedValue}</div>
                                                                    <h3>Unlocked</h3>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="box">
                                                                    <div className="value">{claimedValue}</div>
                                                                    <h3>Claimed</h3>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="box balance">
                                                                    <div className="value">{lepaValue}</div>
                                                                    <h3>Current Balance</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="text-center"><button onClick={claimNowProcess} className={buttonClassName}>{claimedString}</button></div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </div>)}
                            {/* <div className="bottom">Vesting End Date : {vestingDate}</div> */}
                        </div>
                    </div>
                </main>
                {loaderShow ?
                    (<div className="page-loader">
                        <div className="box">
                            <div>
                                <div className="loader"></div>
                                <div>Processing...</div>
                            </div>
                        </div>
                    </div>) : <></>}
                {!networkLoaderShow ?
                    (<div className="page-loader">
                        <div className="box">
                            <div>Please Select Ethereum Main Network</div>
                        </div>
                    </div>) : <></>}
                <Footer />

            </div>
        </>
    )
}



function AccordionToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <button
            type="button"
            className={isCurrentEventKey ? 'btn' : 'btn collapsed'}
            onClick={decoratedOnClick}
        >
            {children}
        </button>
    );
}