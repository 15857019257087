import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import eventBus from './EventBus'
import ConnectButton from './ConnectButton.js'
import { Navbar, Modal, Button, Dropdown } from 'react-bootstrap'

export default function Header(props) {
  const history = useHistory()
  const [isConnected, updateConnection] = useState(false)
  const [accountAdd, setaccountAdd] = useState(null)
  const [accountAddress, setaccountAddress] = useState(null)
  const [accountAddBal, setaccountAddBal] = useState(null)
  const [headerHighlight, setheaderHighlight] = useState(0)
  const [copyText, setcopyText] = useState('Copy Address')

  useEffect(() => {
    let currenctURL = window.location.href
    if (currenctURL.indexOf('vip') > -1) {
      setheaderHighlight(1)
    } else if (currenctURL.indexOf('token') > -1) {
      setheaderHighlight(2)
    } else if (currenctURL.indexOf('collection') > -1) {
      setheaderHighlight(3)
    } else if (currenctURL.indexOf('team') > -1) {
      setheaderHighlight(4)
    }

    if (window.localStorage.getItem('accountAddress') !== null) {
      updateConnection(true)
      setDisplayAdd(window.localStorage.getItem('accountAddress'))
      setaccountAddBal(window.localStorage.getItem('accountAddressBal'))
    }
    window.scrollTo(0, 0)
  }, [])

  eventBus.on('accountBalEvent', (params) => {
    updateConnection(true)
    setDisplayAdd(params.data)
    setaccountAddBal(params.data)
  })

  eventBus.on('walletConnected', (params) => {
    updateConnection(params.data)
  })

  eventBus.on('accountEvent', (params) => {
    setDisplayAdd(params.data)
  })

  function headerClickHandle(id, url) {
    history.push(url)
  }

  function setDisplayAdd(accountAddress) {
    window.localStorage.setItem('accountAddress', accountAddress)
    setaccountAddress(accountAddress)
    let add =
      accountAddress.substring(0, 6) +
      '...' +
      accountAddress.substring(accountAddress.length - 5, accountAddress.length)
    setaccountAdd(add)
  }

  function showDisconnectModel() {
    handleClose()
    eventBus.dispatch('disconnectModel', { data: null })
  }

  function openEtherscan() {
    let url = 'https://etherscan.io/address/' + accountAddress
    let win = window.open(url, '_blank')
    win.focus()
  }

  function copyAddress() {
    navigator.clipboard.writeText(accountAddress)
    setcopyText('Copied')
    setTimeout(() => {
      setcopyText('Copy Address')
    }, 500)
  }

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [modalShow, setModalShow] = useState(false)
  const modalClose = () => setModalShow(false)
  const modalOpen = () => setModalShow(true)


  return (
    <>
      <header id="header" className="site-header">
        <div className="container">
          <Navbar className="header_main" expand="md">
            <Navbar.Brand className="logo mr-auto" href="/"><img src="./assets/img/lepasa-logo.svg" alt="" /></Navbar.Brand>


            {isConnected ? (
              <div className='headRightBox'>

                {
                  <Dropdown className="menu-item d-block">
                    <Dropdown.Toggle variant="link" className="wallet-info">
                      <span className="wallet">{accountAdd} <span className="identication" id="accountIdentication-mobile"></span></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => showDisconnectModel()} href="#/">Disconnect</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                }
              </div>
            ) : (
              <></>
            )}

            <Navbar.Toggle className="menu-bar" aria-controls="site-navbars" aria-expanded="false" aria-label="Toggle navigation">
              <span></span>
              <span></span>
              <span></span>
              <i className="sr-only">Menu Toggle</i>
            </Navbar.Toggle>

          </Navbar>
        </div>
      </header>
      <Modal show={show} onHide={handleClose} centered className="account-modal">
        <Modal.Header>
          <Modal.Title>Account</Modal.Title>
          <button className="close-btn" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="sc-fMiknA hTHyXD"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="box">
            <div className="top d-flex justify-content-between align-items-center">
              Connected with MetaMask
              <Button onClick={() => showDisconnectModel()} variant="outline-primary">
                Change
              </Button>
            </div>
            <h3>
              <img src="./assets/img/coinbase-wallet.png" alt="" /> {accountAdd}
            </h3>
            <a href="/#" className="link" onClick={copyAddress}>
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                  <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                </svg>
              </span>
              {copyText}
            </a>
            <a href="/#" className="link" onClick={openEtherscan}>
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                  <polyline points="15 3 21 3 21 9"></polyline>
                  <line x1="10" y1="14" x2="21" y2="3"></line>
                </svg>
              </span>
              View on Etherscan
            </a>
          </div>
          <div className="bottom">Your transactions will appear here...</div>
        </Modal.Body>
      </Modal>

      <Modal show={modalShow} onHide={modalClose} className="site-modal" centered>
        <Modal.Header>
          <Modal.Title>Connect Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropdown>
            <Dropdown.Toggle>Select <img src="assets/img/down-arrow.png" alt="" /></Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#/"><img src="assets/img/eth.png" alt="" /> ETH</Dropdown.Item>
              <Dropdown.Item href="#/"><img src="assets/img/matic.png" alt="" /> POLY <span>MATIC</span></Dropdown.Item>
              <Dropdown.Item href="#/"><img src="assets/img/bnb.png" alt="" /> BNB</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Button variant="primary" className="button" size="lg" onClick={modalClose}>Confirm</Button>
        </Modal.Body>
      </Modal>
    </>
  )
}
