import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Home";
import Collection from "./Collection";
import Token from "./Token";
import Team from "./Team";
import Dashboard from "./Dashboard";
function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Dashboard} />
        {/* <Route path="/vip" component={VIP} /> */}
        {/* <Route path="/collection" component={Collection} />
        <Route path="/token" component={Token} />
        <Route path="/team" component={Team} />
        <Route path="/dashboard" component={Dashboard} /> */}
      </Switch>
    </Router>
  );
}

export default App;
