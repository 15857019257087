import React from 'react'

export default function Footer() {


    console.log = function () { }


    return (
        <footer id="footer" className="site-footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <img src="assets/img/lepasa-logo-light.svg" alt="" className="footer-logo" />
                        <p>We’re building an ecosystem that will bring everyone who believes in innovation to join the Crypto & NFT revolution. The world is moving on to witness an unprecedented pace. With Lepasa, you can experience all possible opportunities and fun.</p>
                    </div>
                    <div className="col-md-4 social-list">
                        <h3>Social Media</h3>
                        <a href="https://lepasa.medium.com/" rel="noreferrer" target="_blank"><img src="assets/img/medium-m.png" alt="" /></a>
                        <a href="https://twitter.com/lepasaorg" rel="noreferrer" target="_blank"><img src="assets/img/twitter.png" alt="" /></a>
                        <a href="https://t.me/lepasaorg" rel="noreferrer" target="_blank"><img src="assets/img/telegram-plane.png" alt="" /></a>
                    </div>
                </div>
            </div>
            <div className="copyright">
                &copy; 2021 Lepasa. All right reserved.
            </div>
        </footer>
    )
}
